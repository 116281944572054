interface IProps {
  size?: number;
  color?: string;
  backgroundColor?: string;
}

export const CloseIcon = ({ size = 50, color, backgroundColor }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25"
        cy="25"
        r="25"
        transform="rotate(-180 25 25)"
        className="fill-black dark:fill-white"
        style={{ fill: backgroundColor }}
        fillOpacity="0.4"
      />

      <path
        d="M34.8623 19.2744C35.7805 18.4285 35.8311 17.0071 34.9753 16.0995C34.1195 15.1919 32.6814 15.1419 31.7632 15.9878L25.1129 22.1143L18.9146 15.541C18.0588 14.6334 16.6207 14.5834 15.7025 15.4293C14.7843 16.2752 14.7337 17.6966 15.5895 18.6042L21.7878 25.1775L15.1375 31.304C14.2192 32.1499 14.1686 33.5713 15.0244 34.4789C15.8802 35.3865 17.3184 35.4365 18.2366 34.5906L24.8869 28.4641L31.0851 35.0374C31.9409 35.945 33.379 35.995 34.2973 35.1491C35.2155 34.3032 35.2661 32.8818 34.4103 31.9742L28.212 25.4009L34.8623 19.2744Z"
        className={'fill-black dark:fill-white'}
        style={{ fill: color }}
      />
    </svg>
  );
};
