import { Variants, useReducedMotion } from 'framer-motion';

export const useAnimation = () => {
  const shouldReduceMotion = useReducedMotion();

  const variants: Record<string, Variants> = {
    item: {
      hidden: {
        opacity: shouldReduceMotion ? 1 : 0,
      },
      show: {
        opacity: 1,
      },
    },
  };

  return { variants };
};
