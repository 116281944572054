interface IProps {
  className?: string;
  size?: number;
}

export const Spinner = ({ className = '', size = 50 }: IProps) => {
  return (
    <div
      className={`relative ${className}`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <svg
        className=" z-10 animate-spin"
        width={size}
        height={size}
        viewBox="0 0 50 50"
      >
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
          style={{
            color: 'inherit',
            stroke: 'currentcolor',
            strokeLinecap: 'round',
            animation: 'dash 1.5s ease-in-out infinite',
          }}
        ></circle>
      </svg>
    </div>
  );
};
