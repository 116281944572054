import { useProgressDialog } from './ProgressDialog.hooks';
import { Spinner } from './Spinner';

export const ProgressDialog = () => {
  const { state } = useProgressDialog();

  if (!state.isVisible) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 z-[999] flex items-center justify-center bg-black/10 backdrop-blur-sm dark:bg-white/10">
      <div className="flex flex-col items-center rounded-lg bg-white p-4 shadow-md dark:bg-white dark:text-black">
        <div className="text-carnationRed">
          <Spinner />
        </div>

        {state.text && (
          <div className="mt-2 text-center text-sm">{state.text}</div>
        )}
      </div>
    </div>
  );
};
