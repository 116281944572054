import { useCallback } from 'react';

import { atom, useRecoilState } from 'recoil';
import ShortUniqueId from 'short-unique-id';

export interface INotification {
  id: string;
  text: string;
}

export const notificationAtom = atom<INotification[]>({
  key: 'notifications',
  default: [],
});

const uid = new ShortUniqueId({ length: 10 });

export const useNotifications = () => {
  const [notifications, setNotifications] = useRecoilState(notificationAtom);

  const removeNotification = useCallback(
    (id: string) => {
      setNotifications(notifications => {
        return notifications.filter(notification => notification.id !== id);
      });
    },
    [setNotifications],
  );

  const addNotification = useCallback(
    (notification: Omit<INotification, 'id'>) => {
      const extendedNotification = { ...notification, id: uid() };

      setTimeout(() => {
        removeNotification(extendedNotification.id);
      }, 5000);

      setNotifications(notifications => [
        extendedNotification,
        ...notifications,
      ]);
    },
    [setNotifications, removeNotification],
  );

  return {
    notifications,
    addNotification,
    removeNotification,
  };
};
