import { useCallback } from 'react';

import { atom, useRecoilState } from 'recoil';

const DEFAULT_STATE = { isVisible: false, text: null };

const stateAtom = atom<{ isVisible: boolean; text: null | string }>({
  key: 'progress_dialog_state',
  default: DEFAULT_STATE,
});

export const useProgressDialog = () => {
  const [state, setState] = useRecoilState(stateAtom);

  const show = useCallback(
    ({ text }: { text: string }) => {
      setState({ isVisible: true, text });
    },
    [setState],
  );

  const hide = useCallback(() => {
    setState(DEFAULT_STATE);
  }, [setState]);

  return { state, show, hide };
};
